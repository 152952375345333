import { getLog } from '@aurora/shared-utils/log';
import React, { useState } from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import { SharedComponent } from '../../../../enums';
import {
  PaginationDirection,
  PaginationUpdateStrategy
} from '../../../../helpers/ui/PaginationHelper/PaginationHelper';
import Icons from '../../../../icons';
import useTranslation from '../../../useTranslation';
import Button from '../../Button/Button';
import { ButtonVariant } from '../../Button/enums';
import { IconColor, IconSize } from '../../Icon/enums';
import Icon from '../../Icon/Icon';
import { PagerLoadMoreVariant, PagerPosition } from '../enums';
import type { PagerLoadMoreProps } from '../types';
import localStyles from './PagerLoadMore.module.css';
import type { FocusableElement } from 'tabbable';
import { tabbable } from 'tabbable';

const log = getLog(module);

/**
 *  The `loadMore` variant for `Pager`. Displays a link that loads and appends
 *  results for the next page to the current list of items.
 *
 * @author Adam Ayres
 */
const PagerLoadMore: React.FC<React.PropsWithChildren<PagerLoadMoreProps>> = ({
  pageInfo,
  loadPage,
  variant = PagerLoadMoreVariant.NONE,
  className,
  useCaret = true,
  position = PagerPosition.CENTER,
  children = null,
  clearLoadingAfterUpdate = false,
  title
}) => {
  const cx = useClassNameMapper(localStyles);
  const { formatMessage, loading: textLoading } = useTranslation(SharedComponent.PAGER_LOAD_MORE);
  const { hasNextPage } = pageInfo;
  const [loading, setLoading] = useState(false);

  if (!hasNextPage || textLoading) {
    return null;
  }

  const text = title || formatMessage('loadMore');
  function wrapper(wrapped: React.ReactElement): React.ReactElement {
    return (
      <div
        className={cx(className, `d-flex justify-content-${position}`, {
          [`lia-pager-wrap-${variant}`]: variant !== PagerLoadMoreVariant.NONE
        })}
      >
        {wrapped}
      </div>
    );
  }

  // Use wrapper when there are children.
  const hasWrapper = !children;

  // LIA-95200 Avoid focusing elements that will open a hovercard when focused.
  function getTabbableElementsNoHovercards() {
    return tabbable(document.body).filter(node => node.dataset.hovercardTrigger !== 'true');
  }

  const button = (
    <Button
      testId="PagerLoadMore.Button"
      className={cx({ [className]: !hasWrapper && className }, 'lia-g-loader-btn lia-pager')}
      title={text}
      variant={ButtonVariant.LINK}
      loading={loading}
      onClick={async (event): Promise<void> => {
        setLoading(true);
        // Keep track of the index of the currently focused element, at this point in time
        // it will be the pager load more button since it was just clicked.
        const tabbableElements = getTabbableElementsNoHovercards();
        const focusedElementIndex = tabbableElements.indexOf(event.target as FocusableElement);

        await Promise.resolve(loadPage?.(PaginationUpdateStrategy.APPEND, PaginationDirection.NEXT))
          .finally(() => {
            if (clearLoadingAfterUpdate) {
              setLoading(false);
            }
          })
          .catch(error => {
            log.error(error, 'Error loading page of results');
          });

        // Focus the element that was at the index before the next page was loaded.
        // Use a timeout to ensure the next page has been loaded and the new elements are in the DOM.
        setTimeout(() => {
          const tabbableElementsAfterLoad = getTabbableElementsNoHovercards();
          tabbableElementsAfterLoad.at(focusedElementIndex)?.focus();
        });
      }}
    >
      {useCaret && (
        <Icon
          icon={Icons.ChevronDownIcon}
          color={IconColor.LOAD_TEXT}
          size={IconSize.PX_16}
          className={cx('lia-g-mr-5')}
        />
      )}
      {children ?? text}
    </Button>
  );
  return hasWrapper ? wrapper(button) : button;
};

export default PagerLoadMore;
